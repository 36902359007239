import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import imageUpload from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import emergencieService from "../../../services/emergencieService";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateNewEmergencySchema } from "../../../schema/CreateNewEmergencySchema";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreateEmergencies({ homeAlert }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [addressEng, setAddressEng] = useState("");
  const [addressMM, setAddressMM] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneNote, setPhoneNote] = useState("");
  const [category, setCategory] = useState("");
  const [link, setLink] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const [, setEmergencies] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(CreateNewEmergencySchema),
    initialValues: {
      name_en: "",
      name_mm: "",
      address_en: "",
      address_mm: "",
      phone: "",
      phone_note: "",
      category: "",
      link: "",
    },
  });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  // const changeNameEng = (e) => {
  //   setNameEng(e.target.value);
  // };
  // const changeNameMM = (e) => {
  //   setNameMM(e.target.value);
  // };
  // const changeAddressEng = (e) => {
  //   setAddressEng(e.target.value);
  // };
  // const changeAddressMM = (e) => {
  //   setAddressMM(e.target.value);
  // };
  // const changePhone = (e) => {
  //   setPhone(e.target.value);
  // };
  // const changePhoneNote = (e) => {
  //   setPhoneNote(e.target.value);
  // };
  // const changeLink = (e) => {
  //   setLink(e.target.value);
  // };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files[0]) {
        const img = e.target.files[0];
        if (!imgFileTypes.includes(img.type)) {
          setErrorMessage({
            ...errorMessage,
            image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 204800) {
          setErrorMessage({
            ...errorMessage,
            image_url: "Image file size must be smaller than 200KB.",
          });
          return;
        }
        setImageFile(img);
        setImagePreview(URL.createObjectURL(img));
        getImageUrl(img);
      }
    }
  };
  const formData = new FormData();

  const handleCreate = async (data) => {
    formData.append("name_en", data?.name_en);
    formData.append("name_mm", data?.name_mm);
    formData.append("address_en", data?.address_en);
    formData.append("address_mm", data?.address_mm);
    formData.append("phone", data?.phone);
    formData.append("phone_note", data?.phone_note);
    formData.append("link", data?.link);
    formData.append("category", data?.category);
    formData.append("image_data", "");
    formData.append("image_url", values?.image_url);

    try {
      setLoading(true);
      await imageUpload.uploadImage(imageFileUrl, imageFile);
      const response = await emergencieService.postEmergencies(
        instanceToken.token,
        formData
      );
      navigate("/emergencies");
      setEmergencies(response.data);
      homeAlert("Emergencies have been created.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };
  return (
    <form onSubmit={handleSubmit(handleCreate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/emergencies">Emergencies</Link>
          <span>Create Emergencies</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={imagePreview}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}
              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 340 * 180 px , Aspect ratio must be
                  1.8:1 and resolution must be 72ppi
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errorMessage["image_url"]}
                    FormHelperText={errorMessage["image_url"]}
                  />
                </Button>
                <FormHelperText error>
                  {errorMessage["image_url"]}
                </FormHelperText>
              </FormControl>
            </Box>
            {/* name */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* name Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  {...register("name_en")}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FormControl>
              {/* Nmae MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="Name MM"
                  {...register("name_mm")}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* address Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="address_en"
                  label="Address Eng"
                  {...register("address_en")}
                  error={!!errors.address_en}
                  helperText={errors.address_en?.message}
                />
              </FormControl>
              {/* Address MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="address_mm"
                  label="Address MM"
                  {...register("address_mm")}
                  error={!!errors.address_mm}
                  helperText={errors.address_mm?.message}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* phone*/}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="phone"
                  label="Phone"
                  {...register("phone")}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </FormControl>
              {/* phoneNote */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="phone_note"
                  label="Phone Note"
                  {...register("phone_note")}
                  error={!!errors.phone_note}
                  helperText={errors.phone_note?.message}
                />
              </FormControl>

              {/* category */}
              {/* Category */}
              <FormControl sx={{ my: 2 }}>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  {...register("category")}
                  error={!!errors.category}
                  helperText={errors.category?.message}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  <MenuItem value="Emergency Hotline">
                    Emergency Hotline
                  </MenuItem>
                  <MenuItem value="Ambulance">Ambulance</MenuItem>
                  <MenuItem value="Oxygen">Oxygen</MenuItem>
                  <MenuItem value="Blood Donation">Blood Donation</MenuItem>
                </Select>
                {/* {errorMessage.category && (
                  <FormHelperText error>{errorMessage.category}</FormHelperText>
                )} */}
                <FormHelperText error>
                  {errorMessage["category"]}
                </FormHelperText>
              </FormControl>

              {/* link */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  {...register("link")}
                  error={!!errors.link}
                  helperText={errors.link?.message}
                />
              </FormControl>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
