import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import imageUpload from "../../../services/upload";
import {
  TextField,
  FormControl,
  CardActions,
  CardMedia,
  Box,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  Button,
  FormHelperText,
} from "@mui/material";
import bannerService from "../../../services/bannerService";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import { createNewBannerSchema } from "../../../schema/createNewBannerSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
export default function UpdateBanner({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [link, setLink] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [banners, setBanners] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createNewBannerSchema),
    initalValues: {
      name_en: "",
      name_mm: "",
      link: "",
    },
  });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    console.log(result);
    const path = IMAGE_PATH + result?.imageName;
    console.log("path=============>", path);
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  useEffect(() => {
    if (!banners) {
      fetchBanners();
    }
  }, []);

  useEffect(() => {
    setNameMM(banners?.name_mm);
    setNameEng(banners?.name_en);
    setLink(banners?.link);

    if (banners) {
      setValue("name_en", banners?.name_en);
      setValue("name_mm", banners?.name_mm);
      setValue("link", banners?.link);

      setValues({
        image_url: banners?.image_url || "",
      });
    }
  }, [banners, setValue]);

  const fetchBanners = async () => {
    try {
      const res = await bannerService.getOneBanner(instanceToken.token, id);
      setBanners(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };
  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };

  const formData = new FormData();
  const handleUpdate = async (data) => {
    // e.preventDefault();

    formData.append("name_en", data?.name_en);
    formData.append("name_mm", data?.name_mm);
    formData.append("link", data?.link);
    formData.append(
      "image_url",
      values?.image_url ? values?.image_url : data?.image_url
    );
    formData.append("image_data", "");

    try {
      setLoading(true);
      if (imageFile) {
        await imageUpload.uploadImage(imageFileUrl, imageFile);
      }
      const response = await bannerService.putBanner(
        instanceToken.token,
        id,
        formData
      );
      navigate("/banners");
      setBanners(response.data);
      homeAlert("News banners have been updated.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/banners">Banners</Link>
          <span>Update Banners</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0 }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                height="320"
                image={imagePreview ? imagePreview : values?.image_url}
                sx={{ my: 2 }}
              />

              <FormControl sx={{ my: 2 }}>
                <Typography
                  sx={{ fontSize: "12px", mb: 1, textAlign: "center" }}
                >
                  Rendered size must be 1920 * 1200 px and Aspect ratio must be
                  16: 10
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>
                  {errorMessage["image_url"]}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                ml: "2rem",
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  {...register("name_en")}
                  onChange={changeNameEng}
                  value={nameEng}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                  // value={nameEng}
                  // onChange={changeNameEng}
                  // error={errorMessage["name_en"]}
                  // helperText={errorMessage["name_en"]}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="name_mm"
                  {...register("name_mm")}
                  onChange={changeNameMM}
                  value={nameMM}
                  error={!!errors.name_mm}
                  helperText={errors.name_mm?.message}
                  // value={nameMM}
                  // onChange={changeNameMM}
                  // error={errorMessage["name_mm"]}
                  // helperText={errorMessage["name_mm"]}
                />
              </FormControl>

              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  {...register("link")}
                  onChange={changeLink}
                  value={link}
                  error={!!errors.link}
                  helperText={errors.link?.message}
                  // value={link}
                  // onChange={changeLink}
                  // error={errorMessage["link"]}
                  // helperText={errorMessage["link"]}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
