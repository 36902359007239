import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import partnerService from "../../../services/partners";
import imageUpload from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import partners from "../../../services/partners";
import { CreateNewPrivilegeSchema } from "../../../schema/CreateNewPrivilegeSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function UpdatePrivilege({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = React.useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [addressEng, setAddressEng] = useState("");
  const [addressMM, setAddressMM] = useState("");
  const [phone, setPhone] = useState("");

  const [phoneNote, setPhoneNote] = useState("");
  const [link, setLink] = useState("");

  const [errorMessage, setErrorMessage] = useState({});

  const [partner, setPartner] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(CreateNewPrivilegeSchema),
    initialValues: {
      name_en: "",
      name_mm: "",
      address_en: "",
      address_mm: "",
      phone: "",
      phone_note: "",
      category: "",
      link: "",
    },
  });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  useEffect(() => {
    if (!partner) {
      fetchPartner();
    }
  }, []);

  useEffect(() => {
    setNameEng(partner?.name_en);
    setNameMM(partner?.name_mm);
    setAddressEng(partner?.address_en);
    setAddressMM(partner?.address_mm);
    setLink(partner?.link);
    setPhone(partner?.phone);
    setPhoneNote(partner?.phone_note);
    setCategory(partner?.category);

    if (partner) {
      setValue("name_en", partner?.name_en);
      setValue("name_mm", partner?.name_mm);
      setValue("link", partner?.link);
      setValue("address_en", partner?.address_en);
      setValue("address_mm", partner?.address_mm);
      setValue("phone", partner?.phone);
      setValue("phone_note", partner?.phone_note);
      setValue("category", partner?.category);

      setValues({
        image_url: partner?.image_url || "",
      });
    }
  }, [partner, setValue]);

  const fetchPartner = async () => {
    try {
      const res = await partnerService.getPartner(instanceToken.token, id);
      setPartner(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };
  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeAddressEng = (e) => {
    setAddressEng(e.target.value);
  };
  const changeAddressMM = (e) => {
    setAddressMM(e.target.value);
  };
  const changePhone = (e) => {
    setPhone(e.target.value);
  };
  const changePhoneNote = (e) => {
    setPhoneNote(e.target.value);
  };
  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeCategory = (e) => {
    setCategory(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files[0]) {
        const img = e.target.files[0];
        if (!imgFileTypes.includes(img.type)) {
          setErrorMessage({
            ...errorMessage,
            image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 204800) {
          setErrorMessage({
            ...errorMessage,
            image_url: "Image file size must be smaller than 200KB.",
          });
          return;
        }
        setImageFile(img);
        setImagePreview(URL.createObjectURL(img));
        getImageUrl(img);
      }
    }
  };

  const formData = new FormData();

  const handleUpdate = async (data) => {
    formData.append("name_en", data?.name_en);
    formData.append("name_mm", data?.name_mm);
    formData.append("address_en", data?.address_en);
    formData.append("address_mm", data?.address_mm);
    formData.append("phone", data?.phone);
    formData.append("phone_note", data?.phone_note);
    formData.append("link", data?.link);
    formData.append("category", data?.category);
    formData.append("image_data", "");
    formData.append(
      "image_url",
      values?.image_url ? values?.image_url : data?.image_url
    );

    try {
      setLoading(true);
      if (imageFile) {
        await imageUpload.uploadImage(imageFileUrl, imageFile);
      }

      const response = await partners.putPartner(
        instanceToken.token,
        id,
        formData
      );
      setPartner(response.data);
      navigate("/privileges");
      homeAlert("New Privilege have been updated.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };
  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/privileges">Privilege</Link>
          <span>Create Privilege</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={imagePreview ? imagePreview : values?.image_url}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}
              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 340 * 180 px , Aspect ratio must be
                  1.8:1 and resolution must be 72ppi
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errorMessage["image_url"]}
                    helperText={errorMessage["image_url"]}
                  />
                </Button>
                {/* {errorMessage.category && (
                  <FormHelperText error>{errorMessage.category}</FormHelperText>
                )} */}
                <FormHelperText error>
                  {errorMessage["image_data"]}
                </FormHelperText>
              </FormControl>
            </Box>
            {/* name */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* name Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  {...register("name_en")}
                  onChange={changeNameEng}
                  value={nameEng}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FormControl>
              {/* Nmae MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="Name MM"
                  {...register("name_mm")}
                  onChange={changeNameMM}
                  value={nameMM}
                  error={!!errors.name_mm}
                  helperText={errors.name_mm?.message}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* address Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="address_en"
                  label="Address Eng"
                  {...register("address_en")}
                  onChange={changeAddressEng}
                  value={addressEng}
                  error={!!errors.address_en}
                  helperText={errors.address_en?.message}
                />
              </FormControl>
              {/* Address MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="address_mm"
                  label="Address MM"
                  {...register("address_mm")}
                  onChange={changeAddressMM}
                  value={addressMM}
                  error={!!errors.address_mm}
                  helperText={errors.address_mm?.message}
                />
              </FormControl>
            </Box>
            {/* description */}
            {/* <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              description Eng
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="description_en"
                  label="Description Eng"
                  value={parEng}
                  onChange={(e) => setParEng(e.target.value)}
                  error={errorMessage["description_en"]}
                  helperText={errorMessage["description_en"]}
                />
              </FormControl>
              description MM
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description_mm"
                  label="Description MM"
                  value={parMM}
                  onChange={(e) => setParMM(e.target.value)}
                  error={errorMessage["description_mm"]}
                  helperText={errorMessage["description_mm"]}
                />
              </FormControl>
            </Box> */}

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* phone*/}
              <FormControl sx={{ my: 2, maxWidth: 800 }}>
                <TextField
                  id="phone"
                  label="Phone"
                  {...register("phone")}
                  onChange={changePhone}
                  value={phone}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </FormControl>

              {/* Email*/}
              {/* <FormControl sx={{ my: 2, maxWidth: 800 }}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errorMessage["email"]}
                  helperText={errorMessage["email"]}
                />
              </FormControl> */}
              {/* phoneNote */}
              <FormControl sx={{ my: 2 }}>
                <TextField
                  id="phone_note"
                  label="Phone Note"
                  {...register("phone_note")}
                  onChange={changePhoneNote}
                  value={phoneNote}
                  error={!!errors.phone_note}
                  helperText={errors.phone_note?.message}
                />
              </FormControl>

              {/* link */}
              <FormControl sx={{ my: 2 }}>
                <TextField
                  id="link"
                  label="Link"
                  {...register("link")}
                  onChange={changeLink}
                  value={link}
                  error={!!errors.link}
                  helperText={errors.link?.message}
                />
              </FormControl>
              {/* Category */}
              {/* Category */}
              <FormControl sx={{ my: 2 }}>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  {...register("category")}
                  onChange={changeCategory}
                  value={category}
                  error={!!errors.category}
                  helperText={errors.category?.message}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  <MenuItem value="Hospital">Hospital</MenuItem>
                  <MenuItem value="Private-Hospital">Private-Hospital</MenuItem>
                  <MenuItem value="Education">Education</MenuItem>
                  <MenuItem value="Fitness">Fitness</MenuItem>
                  <MenuItem value="Hotel">Hotel</MenuItem>
                </Select>
                {errorMessage.category && (
                  <FormHelperText error>{errorMessage.category}</FormHelperText>
                )}
                <FormHelperText error>
                  {errorMessage["category"]}
                </FormHelperText>
              </FormControl>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
